import React from "react";
import "./InvestmentScams.css";
import Navbar from '../components/Navbar';
import { Link, useNavigate } from 'react-router-dom';
import { BsArrowLeft, BsArrowRight } from 'react-icons/bs';


const RomanceScams = () => {
  const navigate = useNavigate(); 
  return (
    <div>
      <Navbar/>
    <div className="scams-container">
      <div className="scam-intro romance-image-style">
        <h1 className="type-title">Romance scams</h1>
        <p className="type-subtitle">
        Scammers exploit friendships and dating to gain your trust and steal your money.
        </p>
        <div className="type-info-box">
          <p>Be cautious when making friends online, as scammers may pretend to be someone else to gain your trust and money. They often create fake profiles and use flattering words to make you feel important.  </p>
          <p>If someone you've met online asks for money or favors, be skeptical, as they might be trying to deceive you. Trust your instincts and don't hesitate to ask for advice from someone you trust before giving away personal information or money.</p>
        </div>
     </div>
      <h2 className="red-flags-title">Red flags that it might be a scam</h2>
      <div className="red-flags-box">
        <ul>
          <li>
          You are made feel special
          </li>
          <li>
          Scammers will influence you to trust them.
          </li>
          <li>
          They will never meet in person or show themselves on camera. Pictures of them might be fake.
          </li>
          <li>Conversations with them start on social media or dating websites but they will ask to move to other platforms for private conversations.</li>
        </ul>
      </div>
      <h2 className="tips-title">What you can do to protect yourself</h2>
      <div className="tips">
      <ul>
          <li>
          Never send money to someone you have not met in person
          </li>
          <li>
          Check if the person is who they say they are
          </li>
          <li>
          Be careful what you share online
          </li>
        </ul>

      </div>
    </div>
    <section className="result-section">
          <div className="result-actions">
            {/* Navigation buttons */}
            <button className="homeButton" onClick={() => navigate('/investment-scams')}><BsArrowLeft/>Investment Scams </button>
            <button className="learn-more" onClick={() => navigate('/impersonation-scams')}>Impersonation Scams <BsArrowRight /></button>
          </div>
        </section>
    <footer className="footer">
          <div className="footer-center">
            <p className="text-footer">
              © 2024 <strong>Agile Rangers</strong>. All rights reserved.
            </p>
          </div>
        </footer>
    </div>
  );
};
export default RomanceScams;