import React from 'react';

function Scamalerts() {
  const scamKeywords = [
    "URGENT",
    "IMMEDIATE ATTENTION",
    "Suspicious URLs like http://",
    "update your personal details"
  ];
  const introduction = "Watch out for certain terms when reading text messages !";

  return (
    <div className="notifications-container">
      <p className="scam-introduction">{introduction}</p>
      <p className="scam-keywords">
        <strong>Common scam triggers:</strong> {scamKeywords.join(', ')}.
      </p>
    </div>
  );
}
export default Scamalerts;




