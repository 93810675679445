// Import necessary dependencies and components
import React, { useState, useEffect } from 'react'; 
import axios from 'axios';
import '../App.css'; // Import CSS file for global styles
import Navbar from '../components/Navbar'; // Import Navbar component
import { Link, useNavigate, useHistory } from 'react-router-dom'; // Import Link for navigation
import { BsArrowLeft, BsArrowRight } from 'react-icons/bs'; // Import the arrow left icon from React Icons
import service from '../axios/service'; // Import Axios service


  // Define the HelpSupport component
const HelpSupport = () => {

	const navigate = useNavigate(); // Navigate function for programmatic navigation
	const [selectedValue, setSelectedValue] = useState('value-3'); // Initial selected value

	const handleChange = (event) => {
		const selectedValue = event.target.value;
		setSelectedValue(selectedValue); // Update the selected value in state
		switch (selectedValue) {
		case 'value-1':
			navigate('/');
			break;
		case 'value-2':
			navigate('/detectscam');
			break;
		case 'value-3':
			navigate('/helpsupport');
			break;
		case 'value-4':
			navigate('/scamquiz');
			break;
		case 'value-5':
			navigate('/scamtypes');
			break;
		case 'value-6':
			navigate('/scamtrends');
			break;
		default:
			break;
		}
	};

  return (
    <div>
		<Navbar/>

		{/* Header section */}
		<section className="header">
        <h1 className="text-header">Have you been scammed?</h1>
        <p className="text-header">Steps to take after falling victim to a scam</p>  
      </section>

      {/* Main content section */}
      <section style={{
        width: '100%',
        height: '100%',
        background: 'linear-gradient(135deg, #AEECEF, #017b93)',
      }}>

		{/* Individual cards with information and actions */}
        <div className='card-container'>

				{/* Card 1 */}
				<div className="card">
					<span className="card__title"><strong>Step 1:</strong> Act Fast</span>
					<p className="card__text">
					It is crucial to act swiftly to minimize potential damage.<br />
					<div className="empty-line"></div> {/* Empty line */}
					Here are the steps you should take:<br />
					<ol className='indented-list'>
						<li>Contact your bank or card provider immediately to report the scam. Request your bank to stop any transactions.</li>
						<li>Change your passwords to any accounts which may be accessed (Bank).</li>
						<li>Contact your telecommunications provider for advice to block future phishing attempts.</li>
					</ol>
					<div className="empty-line"></div> {/* Empty line */}
					Want to prevent yourself from getting unsolicited telemarketing calls? Register your phone number on <strong>DO NOT CALL REGISTER</strong>.
					</p>
					<a href="https://www.donotcall.gov.au/" target="_blank">
					<button className="card__button">Register</button>
					</a>
				</div>

	  			{/* Card 2 */}
				<div className="card">
					<span className="card__title"><strong>Step 2:</strong> Get Help</span>
					<p className="card__text">
						Not satisfied with the way your bank handled your case? <br />
						Make a complaint to the <strong>Australian Financial Complaints Authority</strong>.
					</p>
					<a href="https://www.afca.org.au/make-a-complaint" target="_blank">
    					<button class="card__button">File complaint</button>
					</a>
					<p className="card__text">
						How do i make a complaint?
					</p>
					<a href="https://www.youtube.com/watch?v=EK8PlurJZog&ab_channel=AustralianFinancialComplaintsAuthority" target="_blank">
    					<button class="card__button">Watch video</button>
					</a>
					{/*<div className="empty-line"></div> 
					<span className="card__title">Consider contacting IDCARE</span>
					<p className="card__text">
						<strong>IDCARE</strong> is a free service in Australia and New Zealand. Their excellent response and mitigation services have helped thousands of Australian and New Zealander individuals and companies lessen the harm caused by identity information breach and misuse.
					</p>
					<a href="https://www.idcare.org/" target="_blank">
    					<button class="card__button">IDCARE</button>
					</a>*/}
				</div>

	  			{/* Card 3 */}
				<div className="card">
					<span className="card__title"><strong>Step 3:</strong> Report Scam</span>
					<p className="card__text">
						You can help us warn others by reporting to <strong>ScamWatch.</strong> <br />
						ScamWatch collects reports about scams to help warn others and take action to fight against scams.
					</p>
					<a href="https://www.scamwatch.gov.au/report-a-scam" target="_blank">
    					<button class="card__button">ScamWatch</button>
					</a>
				</div>

	  			{/* Card 4 */}
				<div className="card">
					<span className="card__title"><strong>Step 4:</strong> Watch out for follow up scams</span>
					<p className="card__text">
						Scammers frequently return for more money if they have already taken it from you. <br />

						<strong>1 in 3</strong> scam victims have fallen victim to scams more than once.
					</p>
				</div>

	  			{/* Card 5 */}
				<div className="card">
					<span className="card__title"><strong>Step 5:</strong> Get Support</span>
					<p className="card__text">
						You might feel like it is your fault but <strong>IT IS NOT</strong>. Anyone could be a victim of a scam which is a terrible experience.  
						<div className="empty-line"></div> {/* Empty line */}
						If you need someone to talk to, don't be embarrassed to speak to <strong>family and friends</strong>. 
						<div className="empty-line"></div> {/* Empty line */}
						View our resources section below to find out <strong>alternative ways</strong> to find support. 
					</p>
				</div>

			</div>

		</section>


		<section style={{ /* Styling for the section */
			width: '100%', /* Full width */
			height: '100%', /* Full height */
			background: 'white', /* White background */
		}}>

			{/* Container for the card heading */}
			<div className='card-heading'>
				<p className="centered-description"><strong>Resources</strong></p>
			</div>

			{/* Container for the cards */}
			<div className='card-container'>

				{/* Card 1 */}
				<div className="hs-card">
					<div className="icon">
						<img src={require('../assets/checkbox.png')} alt="Message Image" />
					</div>
					<strong>Beyond Blue</strong>
					<div className="hs-card__body">
						It is your private, safe space to get mental health advice. 
					</div>
					<a href="https://www.beyondblue.org.au/get-support/talk-to-a-counsellor/chat" target="_blank">
						<span>Learn more</span>
					</a>
				</div>

				{/* Card 2 */}
				<div className="hs-card">
					<div className="icon">
						<img src={require('../assets/users.png')} alt="Message Image" />
					</div>
					<strong>Lifeline</strong>
					<div className="hs-card__body">
						They offer confidential one-to-one chat short-term support.
					</div>
					<a href="https://www.lifeline.org.au/crisis-chat/" target="_blank">
						<span>Learn more</span>
					</a>
				</div>

				{/* Card 3 */}
				<div className="hs-card">
					<div className="icon">
						<img src={require('../assets/star.png')} alt="Message Image" />
					</div>
					<strong>IDCARE</strong>
					<div className="hs-card__body">
						They help minimise the compromise and misuse of identity information.					
					</div>
					<a href="https://www.idcare.org/" target="_blank">
						<span>Learn more</span>
					</a>
				</div>

			</div>


			{/* Button to scroll up */}
			<div className='button-homex'>
				<a href="#" className="button-home">
					{/* Upward arrow SVG icon */}
					<svg className="svgIcon" viewBox="0 0 384 512">
						<path
							d="M214.6 41.4c-12.5-12.5-32.8-12.5-45.3 0l-160 160c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L160 141.2V448c0 17.7 14.3 32 32 32s32-14.3 32-32V141.2L329.4 246.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3l-160-160z"
						></path>
					</svg>
				</a>
			</div>


			{/*Bottom nav bar */}
			<div className="radio-input">
				<label>
					<input
						value="value-1"
						name="value-radio"
						id="value-1"
						type="radio"
						checked={selectedValue === 'value-1'}
						onChange={handleChange}
					/>
					<a href="/" target="_top">Home</a>
				</label>
				<label>
					<input
						value="value-2"
						name="value-radio"
						id="value-2"
						type="radio"
						checked={selectedValue === 'value-2'}
						onChange={handleChange}
					/>
					<a href="/detectscam" target="_top">Detect Scam</a>
				</label>
				<label>
					<input
						value="value-3"
						name="value-radio"
						id="value-3"
						type="radio"
						checked={selectedValue === 'value-3'}
						onChange={handleChange}
					/>
					<a href="/helpsupport" target="_top">Help & Support</a>
				</label>
				<label>
					<input
						value="value-4"
						name="value-radio"
						id="value-4"
						type="radio"
						checked={selectedValue === 'value-4'}
						onChange={handleChange}
					/>
					<a href="/scamquiz" target="_top">Scam Spotter</a>
				</label>
				<label>
					<input
						value="value-5"
						name="value-radio"
						id="value-5"
						type="radio"
						checked={selectedValue === 'value-5'}
						onChange={handleChange}
					/>
					<a href="/scamtypes" target="_top">Scam Types</a>
				</label>
				<label>
					<input
						value="value-6"
						name="value-radio"
						id="value-6"
						type="radio"
						checked={selectedValue === 'value-6'}
						onChange={handleChange}
					/>
					<a href="/scamtrends" target="_top">Scam Statistics</a>
				</label>
				<span className="selection"></span>
			</div>


		{/* Footer section */}
		<footer className="footer">
			<div className="footer-center">
				<p className="text-footer">
					© 2024 <strong>Agile Rangers</strong>. All rights reserved.
				</p>
			</div>
		</footer>

	</section>


	</div>
  )
}

export default HelpSupport