import React from "react";
import "./InvestmentScams.css";
import Navbar from '../components/Navbar';
import { Link, useNavigate } from 'react-router-dom';
import { BsArrowLeft, BsArrowRight } from 'react-icons/bs';

const ThreatsScams = () => {
  const navigate = useNavigate(); 
  return (
    <div>
      <Navbar/>
    <div className="scams-container">
      <div className="scam-intro threats-image-style">
        <h1 className="type-title">Threats and extortion scams</h1>
        <p className="type-subtitle">
        Scammers threaten you to get money from you.
        </p>
        <div className="type-info-box">
          <p>Scammers pose as representatives of a company and demand payment.</p>
          <p>In the case that you refuse to give them the money right away, they might threaten to have you arrested, deported, or even hurt physically.</p>
        </div>
     </div>
      <h2 className="red-flags-title">Red flags that it might be a scam</h2>
      <div className="red-flags-box">
        <ul>
          <li>
          Someone pretending to be from a reputable company, debt collection agency, or government department unexpectedly contacts you by phone, email, or message.
          </li>
          <li>
          The caller may ask for your personal information
          </li>
          <li>
          The caller may claim the police will come to your door and arrest you
          </li>
          <li>You are asked to transfer money to an account to ‘keep it safe’ or for ‘further investigation’</li>
        </ul>
      </div>
      <h2 className="tips-title">What you can do to protect yourself</h2>
      <div className="tips">
      <ul>
          <li>
          Hang up and don’t respond
          </li>
          <li>
          Don’t use any contact details provided by the caller. Confirm their identity by placing a direct call to the appropriate organisation.
          </li>
        </ul>

      </div>
    </div>
    <section className="result-section">
          <div className="result-actions">
            {/* Navigation buttons */}
            <button className="homeButton" onClick={() => navigate('/impersonation-scams')}><BsArrowLeft/>Impersonation Scams</button>
            <button className="learn-more" onClick={() => navigate('/unexpected')}>Unexpected Money Scams<BsArrowRight /></button>
          </div>
        </section>
    <footer className="footer">
          <div className="footer-center">
            <p className="text-footer">
              © 2024 <strong>Agile Rangers</strong>. All rights reserved.
            </p>
          </div>
        </footer>
    </div>
  );
};

export default ThreatsScams;