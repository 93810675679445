import React from 'react';
import { NavLink } from 'react-router-dom'; // Import NavLink from react-router-dom for active link styling
import './NavbarStyles.css'; // Import the CSS file for styling
import Image from '../assets/Scamsecurelogo.png'; // Import the logo image

const Navbar = () => {
  return (
    <div>
		{/* Navigation bar */}
		<nav className="navbar background">
			<div className='logo'>
				{/* Logo with link */}
				<a href="/">
					<img src={Image} alt="Logo" />
				</a>
				{/*<NavLink to='/' activeClassName="active"><h1>SCAM SECURE</h1></NavLink>*/}
			</div>
			{/* Navigation links list */}
			<ul className="nav-list">
				{/* NavLink for Home */}
				<li>
					<NavLink exact to='/' activeClassName="active">Home</NavLink>
				</li>
				{/* NavLink for Detect Scam */}
				<li>
					<NavLink to='/detectscam' activeClassName="active">Detect Scam</NavLink>
				</li>
				{/* NavLink for Help & Support */}
				<li>
					<NavLink to='/helpsupport' activeClassName="active">Help & Support</NavLink>
				</li>
				{/* NavLink for ScamQuiz */}
				<li>
					<NavLink to='/scamquiz' activeClassName="active">Scam Spotter</NavLink>
				</li>
				{/* NavLink for ScamTypes */}
				<li>
					<NavLink to='/scamTypes' activeClassName="active">Scam Types</NavLink>
				</li>
				{/* NavLink for ScamTrends */}
				<li>
					<NavLink to='/scamtrends' activeClassName="active">Scam Statistics</NavLink>
				</li>
			</ul>
		</nav>
    </div>
  );
};

export default Navbar;
