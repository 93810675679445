import React, { useState } from 'react'
import Navbar from '../components/Navbar'; // Import Navbar component
import Leaderboard from '../components/Leaderboard'; // Import the Leaderboard component
import { Link, useNavigate } from 'react-router-dom'; // Import Link for navigation
import { BsArrowLeft, BsArrowRight } from 'react-icons/bs'; // Import the arrow left icon from React Icons
import Image from '../assets/background.png'; // Import image asset


const ScamQuiz = () => {

    const navigate = useNavigate(); // Navigate function for programmatic navigation
    const [selectedValue, setSelectedValue] = useState('value-4'); // Initial selected value

    const handleChange = (event) => {
      const selectedValue = event.target.value;
      setSelectedValue(selectedValue); // Update the selected value in state
      switch (selectedValue) {
      case 'value-1':
        navigate('/');
        break;
      case 'value-2':
        navigate('/detectscam');
        break;
      case 'value-3':
        navigate('/helpsupport');
        break;
      case 'value-4':
        navigate('/scamquiz');
        break;
      case 'value-5':
        navigate('/scamtypes');
        break;
      case 'value-6':
        navigate('/scamtrends');
        break;
      default:
        break;
      }
    };

  return (
    <div>
        {/* Top background section */}
        <section style={{
          width: '100%',
          height: '95vh',
          background: 'linear-gradient(135deg, #AEECEF, #017b93)',
          /*backgroundColor: '#F3F3F3',
          backgroundImage: `linear-gradient(0deg, transparent 24%, var(--color) 25%, var(--color) 26%, transparent 27%,transparent 74%, var(--color) 75%, var(--color) 76%, transparent 77%,transparent),
      linear-gradient(90deg, transparent 24%, var(--color) 25%, var(--color) 26%, transparent 27%,transparent 74%, var(--color) 75%, var(--color) 76%, transparent 77%,transparent)`,
    '--color': '#E1E1E1',
          backgroundSize: '55px 55px',
        boxShadow: 'inset 0 0 0 2000px rgba(255, 255, 255, 0.45)', // Create a background overlay */
        }}>

        {/* Render the Navbar component */}
        <Navbar />
        
        <section className="content">
          <div className="box-content">
            <div className="firstHalf">
              {/* Title and description */}
              <h1 className="centered-title">
                Can you spot a scam?
              </h1>
              <p className="centered-description">
                Take this quiz to find out.
              </p>
              {/* Button to learn more */}
              <div>
                {/*<Link to='/Quiz' className='btn'>Start Quiz</Link>*/}
                <Link to='/Quiz' className='button-17'>Start Quiz</Link>
              </div>
            </div>
          </div>
        </section>

        </section>

        {/* Background section with inline styles for Score board */}
        <section style={{
            background: 'ccc',
            width: '100%',
            height: '100%',
        }}>

        <div className='card-heading'>
          <p className="centered-description"><strong>Leaderboard</strong></p>
        </div>

        <Leaderboard />

        {/* Button to scroll up */}
        <div className='button-homex'>
          <a href="#" className="button-home">
            {/* Upward arrow SVG icon */}
            <svg className="svgIcon" viewBox="0 0 384 512">
              <path
                d="M214.6 41.4c-12.5-12.5-32.8-12.5-45.3 0l-160 160c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L160 141.2V448c0 17.7 14.3 32 32 32s32-14.3 32-32V141.2L329.4 246.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3l-160-160z"
              ></path>
            </svg>
          </a>
        </div>


        {/*Bottom nav bar */}
        <div className="radio-input">
          <label>
            <input
              value="value-1"
              name="value-radio"
              id="value-1"
              type="radio"
              checked={selectedValue === 'value-1'}
              onChange={handleChange}
            />
            <a href="/" target="_top">Home</a>
          </label>
          <label>
            <input
              value="value-2"
              name="value-radio"
              id="value-2"
              type="radio"
              checked={selectedValue === 'value-2'}
              onChange={handleChange}
            />
            <a href="/detectscam" target="_top">Detect Scam</a>
          </label>
          <label>
            <input
              value="value-3"
              name="value-radio"
              id="value-3"
              type="radio"
              checked={selectedValue === 'value-3'}
              onChange={handleChange}
            />
            <a href="/helpsupport" target="_top">Help & Support</a>
          </label>
          <label>
            <input
              value="value-4"
              name="value-radio"
              id="value-4"
              type="radio"
              checked={selectedValue === 'value-4'}
              onChange={handleChange}
            />
            <a href="/scamquiz" target="_top">Scam Spotter</a>
          </label>
          <label>
            <input
              value="value-5"
              name="value-radio"
              id="value-5"
              type="radio"
              checked={selectedValue === 'value-5'}
              onChange={handleChange}
            />
            <a href="/scamtypes" target="_top">Scam Types</a>
          </label>
          <label>
            <input
              value="value-6"
              name="value-radio"
              id="value-6"
              type="radio"
              checked={selectedValue === 'value-6'}
              onChange={handleChange}
            />
            <a href="/scamtrends" target="_top">Scam Statistics</a>
          </label>
          <span className="selection"></span>
        </div>

        </section>


        <footer className="footer">
          <div className="footer-center">
            <p className="text-footer">
              © 2024 <strong>Agile Rangers</strong>. All rights reserved.
            </p>
          </div>
        </footer>
        
    </div>
  )
}

export default ScamQuiz