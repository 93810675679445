import React from "react";
import "./InvestmentScams.css";
import Navbar from '../components/Navbar';
import { Link, useNavigate } from 'react-router-dom';
import { BsArrowLeft, BsArrowRight } from 'react-icons/bs';

const UnexpectedScams = () => {
  const navigate = useNavigate(); 
    return (
      <div>
        <Navbar/>
      <div className="scams-container">
        <div className="scam-intro unexpected-image-style">
          <h1 className="type-title">Unexpected money</h1>
          <p className="type-subtitle">
          Avoid losing money when attempting to obtain wins, rebates, or entitlements. Free money offers will end up costing you a lot.  
          </p>
          <div className="type-info-box">
            <p>Scammers will attempt to persuade you that you are entitled to or owe money that you did not expect to receive.</p>
            <p>There are no free lunches and you will lose more. </p>
            <p>Scammers will request a fee or your personal information in order for you to receive money rewards.</p>
          </div>
       </div>
        <h2 className="red-flags-title">Red flags that it might be a scam</h2>
        <div className="red-flags-box">
          <ul>
            <li>
            Unexpected messages, emails, or other correspondence claiming you have a right to money, benefits, or inheritance.
            </li>
            <li>
            A court order that declares you as a recipient and request money or personal details
            </li>
            <li>
            A formal-looking letter or email requests a "administration fee" or tax up front in order to process a return or rebate.
            </li>
          </ul>
        </div>
        <h2 className="tips-title">What you can do to protect yourself</h2>
        <div className="tips">
        <ul>
            <li>
            Paying a fee to claim a prize or wins is not required. You are not required to do this by legitimate lotteries.
            </li>
            <li>
            Never give someone you don't know or trust access to your bank account information, credit card information, or identity documents.
            </li>
            <li>Do an internet search using the names or contact details to check for any references to a scam.</li>
          </ul>
  
        </div>
      </div>
      <section className="result-section">
          <div className="result-actions">
            {/* Navigation buttons */}
            <button className="homeButton" onClick={() => navigate('/threats')}><BsArrowLeft/>Threats Scams</button>
            <button className="learn-more" onClick={() => navigate('/scamtrends')}>Scam Statistics<BsArrowRight /></button>
          </div>
        </section>
      <footer className="footer">
          <div className="footer-center">
            <p className="text-footer">
              © 2024 <strong>Agile Rangers</strong>. All rights reserved.
            </p>
          </div>
        </footer>
      </div>
    );
  };
  
  export default UnexpectedScams;