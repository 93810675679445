// src/pages/ImpersonationScams.js
import React from "react";
import "./InvestmentScams.css";
import Navbar from '../components/Navbar';
import { BsArrowLeft, BsArrowRight } from 'react-icons/bs';
import { Link, useNavigate } from 'react-router-dom';


const ImpersonationScams = () => {
  const navigate = useNavigate(); 
  return (
    <div>
      <Navbar/>
    <div className="scams-container">
      <div className="scam-intro impersonation-image-style">
        <h1 className="type-title">Impersonation scams</h1>
        <p className="type-subtitle">
        Scammers pretend to be reputable companies, loved ones, or acquaintances in order to obtain your money or sensitive personal details.
        </p>
        <div className="type-info-box">
          <p>Scammers use technology to make their call appear to come from a legitimate phone number.</p>
          <p>Scammers trick you into thinking they are from organisations such as the police, government, banks, and well-known businesses.</p>
        </div>
     </div>
      <h2 className="red-flags-title">Red flags that it might be a scam</h2>
      <div className="red-flags-box">
        <ul>
          <li>
          You are requested to supply personal information or money immediately in response to an urgent request for action.
          </li>
          <li>
          A supposedly legitimate company notifies you of an unlawful transaction or requests verification of a payment you did not make.
          </li>
          <li>
          A company requests that you use a different bank account and BSB number from the previous payment.
          </li>
          <li>You get a message requesting you to click on a link that leads to a webpage where they request personal information such as your password or username.</li>
        </ul>
      </div>
      <h2 className="tips-title">What you can do to protect yourself</h2>
      <div className="tips">
      <ul>
          <li>
          Cut off communication with anyone threatening or intimidating you right away.
          </li>
          <li>
          Avoid downloading or opening any attachments since they may include harmful malware that could infect your phone or computer.
          </li>
          <li>
          Look out for slight differences in the Caller or Sender IDs like dots, special characters, or numbers.
          </li>
          <li>
          Don’t click on links in text messages or emails.
          </li>
          <li>Check that the message is real - use the contact information you find on the organization's official website</li>
        </ul>

      </div>
    </div>
    <section className="result-section">
          <div className="result-actions">
            {/* Navigation buttons */}
            <button className="homeButton" onClick={() => navigate('/romance-scams')}><BsArrowLeft/>Romance Scams </button>
            <button className="learn-more" onClick={() => navigate('/threats')}>Threats Scams <BsArrowRight /></button>
          </div>
        </section>
    <footer className="footer">
          <div className="footer-center">
            <p className="text-footer">
              © 2024 <strong>Agile Rangers</strong>. All rights reserved.
            </p>
          </div>
        </footer>
    </div>
  );
};

export default ImpersonationScams;
