import React, { useState } from "react";
import "./ScamTypes.css";
import { FaArrowRight } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import MoneyBagIcon from "../assets/Investment.png";
import HeartIcon from "../assets/Romance.png";
import SpyIcon from "../assets/Impersonation.png";
import Navbar from '../components/Navbar';
import ThreatsIcon from "../assets/Threats.png";
import UnexpectedIcon from "../assets/Unexpected.png";


const scams = [
  {
    title: "Investment Scams",
    description:
      "If you want to invest to make money, beware of scammers that have set up a variety of phoney chances to entice you and steal your money.",
    icon: MoneyBagIcon,
    color: "#b68ef8",
    route: "/investment-scams",
  },
  {
    title: "Romance scams",
    description:
      "Scammers exploit friendships and dating to steal your money. They will stop at nothing to make you believe that the relationship is genuine and coerce you into giving them money.",
    icon: HeartIcon,
    color: "#c4e6f8",
    route: "/romance-scams",
  },
  {
    title: "Impersonation scams",
    description:
      "A type of fraud that uses someone else's identity to steal money or gain other benefits.",
    icon: SpyIcon,
    color: "#c2d6d0",
    route: "/impersonation-scams",
  },
  {
    title: "Threats & Extortion",
    description:
      "Scammers will use anything and everything to steal your personal information and money.",
    icon: ThreatsIcon,
    color: "#FFA500",
    route: "/threats",
  },
  {
    title: "Unexpected Money",
    description:
      "Don’t be fooled by a surprise win. These type of scam will deceive you into giving money or personal information to claim a prize.",
    icon: UnexpectedIcon,
    color: " #87CEFA",
    route: "/unexpected",
  },

];

const ScamTypes = () => {
  const [selectedScam, setSelectedScam] = useState(null);

  const handleCardClick = (route, index) => {
    setSelectedScam(index);
    navigate(route);
  };

  const navigate = useNavigate(); // Navigate function for programmatic navigation
	const [selectedValue, setSelectedValue] = useState('value-5'); // Initial selected value

	const handleChange = (event) => {
		const selectedValue = event.target.value;
		setSelectedValue(selectedValue); // Update the selected value in state
		switch (selectedValue) {
		case 'value-1':
			navigate('/');
			break;
		case 'value-2':
			navigate('/detectscam');
			break;
		case 'value-3':
			navigate('/helpsupport');
			break;
		case 'value-4':
			navigate('/scamquiz');
			break;
		case 'value-5':
			navigate('/scamtypes');
			break;
		case 'value-6':
			navigate('/scamtrends');
			break;
		default:
			break;
		}
	};

  return (
    <div>
        <Navbar/>
    <div className="scam-types-container">
      <h2 className="scam-types-title">What are some common types of scams?</h2>
      <div className="scam-cards">
        {scams.map((scam, index) => (
          <div
            key={index}
            className="scam-card"
            style={{
              backgroundColor: index === selectedScam ? "#ffc107" : scam.color,
            }}
            onClick={() => handleCardClick(scam.route, index)}
          >
            <img src={scam.icon} alt={scam.title} className="scam-icon" />
            <div className="scam-info">
              <h3>{scam.title}</h3>
              <p>{scam.description}</p>
            </div>
            <div className="scam-arrow">
              <FaArrowRight />
            </div>
          </div>
        ))}
      </div>

      </div>

      
      {/* Button to scroll up */}
			<div className='button-homex'>
				<a href="#" className="button-home">
					{/* Upward arrow SVG icon */}
					<svg className="svgIcon" viewBox="0 0 384 512">
						<path
							d="M214.6 41.4c-12.5-12.5-32.8-12.5-45.3 0l-160 160c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L160 141.2V448c0 17.7 14.3 32 32 32s32-14.3 32-32V141.2L329.4 246.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3l-160-160z"
						></path>
					</svg>
				</a>
			</div>


			{/*Bottom nav bar */}
			<div className="radio-input">
				<label>
					<input
						value="value-1"
						name="value-radio"
						id="value-1"
						type="radio"
						checked={selectedValue === 'value-1'}
						onChange={handleChange}
					/>
					<a href="/" target="_top">Home</a>
				</label>
				<label>
					<input
						value="value-2"
						name="value-radio"
						id="value-2"
						type="radio"
						checked={selectedValue === 'value-2'}
						onChange={handleChange}
					/>
					<a href="/detectscam" target="_top">Detect Scam</a>
				</label>
				<label>
					<input
						value="value-3"
						name="value-radio"
						id="value-3"
						type="radio"
						checked={selectedValue === 'value-3'}
						onChange={handleChange}
					/>
					<a href="/helpsupport" target="_top">Help & Support</a>
				</label>
				<label>
					<input
						value="value-4"
						name="value-radio"
						id="value-4"
						type="radio"
						checked={selectedValue === 'value-4'}
						onChange={handleChange}
					/>
					<a href="/scamquiz" target="_top">Scam Spotter</a>
				</label>
				<label>
					<input
						value="value-5"
						name="value-radio"
						id="value-5"
						type="radio"
						checked={selectedValue === 'value-5'}
						onChange={handleChange}
					/>
					<a href="/scamtypes" target="_top">Scam Types</a>
				</label>
				<label>
					<input
						value="value-6"
						name="value-radio"
						id="value-6"
						type="radio"
						checked={selectedValue === 'value-6'}
						onChange={handleChange}
					/>
					<a href="/scamtrends" target="_top">Scam Statistics</a>
				</label>
				<span className="selection"></span>
			</div>


		  {/* Footer section */}
			<footer className="footer">
				<div className="footer-center">
					<p className="text-footer">
					© 2024 <strong>Agile Rangers</strong>. All rights reserved.
					</p>
				</div>
			</footer>
    
    </div>
  );
};

export default ScamTypes;