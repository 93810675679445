import React, { useState, useEffect, useRef } from 'react'; 
import firstImage from '../assets/4.2.png'
import secondImage from '../assets/5.png'
import thirdImage from '../assets/6.png'
import Box1 from '../assets/Box 1.png'
import Box2 from '../assets/Box 2.png'
import Box3 from '../assets/Box 3.png'
import Box4 from '../assets/Box 4.png'
import scamTrendsimage from '../assets/SCAMTRENDS.png';
import scamImpactsimage from '../assets/SCAMIMPACTS.png';
import '../App.css'; // Import CSS file for global styles
import { Link, useNavigate } from 'react-router-dom'; // Import Link component from react-router-dom for navigation
import Navbar from '../components/Navbar'; // Import Navbar component
import * as d3 from "d3";



const ScamTrends = () => {

  const navigate = useNavigate(); // Navigate function for programmatic navigation
	const [selectedValue, setSelectedValue] = useState('value-6'); // Initial selected value

	const handleChange = (event) => {
		const selectedValue = event.target.value;
		setSelectedValue(selectedValue); // Update the selected value in state
		switch (selectedValue) {
		case 'value-1':
			navigate('/');
			break;
		case 'value-2':
			navigate('/detectscam');
			break;
		case 'value-3':
			navigate('/helpsupport');
			break;
		case 'value-4':
			navigate('/scamquiz');
			break;
		case 'value-5':
			navigate('/scamtypes');
			break;
		case 'value-6':
			navigate('/scamtrends');
			break;
		default:
			break;
		}
	};

  // Ref for bar chart container
  const d3Container = useRef(null);
  const colors = ['#e9c46a', '#264653', '#e63946']; // Colors for the bars corresponding to the years
  const years = ['2021', '2022', '2023'];

  // Ref for the horizontal bar chart container
  const horizontalBarChartContainer = useRef(null);

  //Ref for pie chart container
  const pieChartRef = useRef(null);

  //Carousel
  const [currentSlide, setCurrentSlide] = useState(0);
  

  // Hardcoded data for bar chart
  const data = [
    { authority: "Bank/Financial institution", '2021': 27.3, '2022': 32.2, '2023': 48.9 },
    { authority: "Social media", '2021': 8.7, '2022': 11.1, '2023': 9.7 },
    { authority: "Government Org", '2021': 8.4, '2022': 11.8, '2023': 8.7 },
    { authority: "Police Department", '2021': 8.2, '2022': 13, '2023': 12.6 },
    { authority: "Other Authorities", '2021': 9.8, '2022': 9.9, '2023': 8.9 },
    { authority: "Any authority", '2021': 49.5, '2022': 57.4, '2023': 69.4 },
  ];  

  // Data for the horizontal bar chart
  const identityTheftData = [
    { category: "Access bank account or superannuation", percentage: 32.9 },
    { category: "Unknown", percentage: 31.5 },
    { category: "Other", percentage: 18.2 },
    { category: "Open accounts on victim's name", percentage: 14.4 },
    { category: "Loan/Credit Card ", percentage: 14.3 },
  ];

  // Data for pie chart
  const reportingData = [
    { category: "Bank/Financial institution", value: 37.7 },
    { category: "Police", value: 25.6 },
    { category: "Government Org", value: 23.1 },
    { category: "Social media", value: 2.8 },
    { category: "Other", value: 14.5 },
    { category: "Not reported", value: 28.3 }
  ];

  // for bar chart 
  useEffect(() => {
    drawBarChart(data);
  }, []); // Dependency array is empty, so the effect runs once on mount

  // for horizontal bar chart 
  useEffect(() => {
    drawHorizontalBarChart(identityTheftData);
  }, []); // Dependency array is empty so the effect runs once on mount

  useEffect(() => {
    if (reportingData.length > 0) {
      drawPieChart(reportingData, pieChartRef);
    }
  }, [reportingData]); // The dependency array ensures this effect runs when reportingData changes

  const drawBarChart = (fetchedData) => {
    const svg = d3.select(d3Container.current);
    svg.selectAll("*").remove();

    const margin = { top: 20, right: 20, bottom: 60, left: 80 }; // Adjusted for y-axis label visibility
    const width = 600 - margin.left - margin.right;
    const height = 300 - margin.top - margin.bottom;

    // Tooltip setup
    const tooltip = d3.select('body').append('div')
        .attr('class', 'd3-tooltip')
        .style('position', 'absolute')
        .style('visibility', 'hidden')
        .style('padding', '6px')
        .style('background', 'white')
        .style('border', '1px solid #ddd')
        .style('border-radius', '4px')
        .style('pointer-events', 'none');

    // Scales and axes
    const x0 = d3.scaleBand()
      .range([0, width])
      .paddingInner(0.1)
      .domain(fetchedData.map(d => d.authority));

    const x1 = d3.scaleBand()
      .padding(0.05)
      .domain(years)
      .rangeRound([0, x0.bandwidth()]);

    const y = d3.scaleLinear()
      .range([height, 0])
      .domain([0, d3.max(fetchedData, d => Math.max(...years.map(year => d[year])))])
      .nice();

    const xAxis = d3.axisBottom(x0);
    const yAxis = d3.axisLeft(y).tickFormat(d => `${d}%`);

    const chart = svg.append('g')
      .attr('transform', `translate(${margin.left},${margin.top})`);

    // Add x-axis labels
    chart.append('g')
    .attr('class', 'x-axis-labels')
    .attr('transform', `translate(0,${height})`)
    .call(xAxis)
    .selectAll('text')
    .attr('transform', 'translate(40,10)rotate(-5)')
    .style('text-anchor', 'end');

    // Add y-axis labels
    chart.append('g')
      .attr('class', 'y axis')
      .call(yAxis);

    // Bars setup
    const bars = chart.append('g')
      .selectAll('g')
      .data(fetchedData)
      .enter().append('g')
        .attr('transform', d => `translate(${x0(d.authority)}, 0)`);
    
    bars.selectAll('rect')
      .data(d => years.map(year => ({ key: year, value: d[year] })))
      .enter().append('rect')
        .attr('x', d => x1(d.key))
        .attr('width', x1.bandwidth())
        .attr('y', y(0))
        .attr('height', 0)
        .attr('fill', (d, i) => colors[i])
        .attr('class', 'bar')
        .on('mouseover', function (event, d) {
          tooltip.html(`${d.key}: ${d.value}%`)
            .style('visibility', 'visible');
        })
        .on('mousemove', function (event, d) {
          tooltip.style('top', `${event.pageY - 20}px`)
            .style('left', `${event.pageX + 20}px`);
        })
        .on('mouseout', function () {
          tooltip.style('visibility', 'hidden');
        })
        // Animate the bars
        .transition()
        .duration(750)
        .attr('y', d => y(d.value))
        .attr('height', d => height - y(d.value));

    // Clean up the tooltip when unmounting
    return () => tooltip.remove();
  };

  const drawHorizontalBarChart = (data) => {
    const svg = d3.select(horizontalBarChartContainer.current);
    const margin = { top: 20, right: 30, bottom: 40, left: 200 };
    const width = 600 - margin.left - margin.right;
    const height = 300 - margin.top - margin.bottom;

    // Clear previous content
    svg.selectAll('*').remove();

    const x = d3.scaleLinear()
      .domain([0, d3.max(data, d => d.percentage)])
      .range([0, width]);

    // Calculate the maximum percentage value in the data
    const maxPercentage = d3.max(data, d => d.percentage);
    // Determine a suitable number of ticks based on the maximum percentage value
    const numTicks = Math.min(maxPercentage, 5);

    const y = d3.scaleBand()
      .domain(data.map(d => d.category))
      .range([height, 0])
      .padding(0.1);

    const chart = svg.append('g')
      .attr('transform', `translate(${margin.left},${margin.top})`);

    // Add a tooltip container (initially hidden)
    const tooltip = d3.select('body').append('div')
    .attr('class', 'tooltip')
    .style('position', 'absolute')
    .style('visibility', 'hidden')
    .style('background', 'white')
    .style('border', '1px solid #ddd')
    .style('border-radius', '5px')
    .style('padding', '5px')
    .style('opacity', 0.8);

    chart.selectAll('.bar')
    .data(data)
    .enter().append('rect')
    .attr('class', 'bar')
    .attr('width', d => x(d.percentage))
    .attr('y', d => y(d.category))
    .attr('height', y.bandwidth())
    .attr('fill', '#2a454b')
    .on('mouseover', function (event, d) {
      tooltip.style('visibility', 'visible')
             .text(`${d.category}: ${d.percentage}%`)
             .style('top', (event.pageY - 10) + 'px')
             .style('left', (event.pageX + 10) + 'px');
      d3.select(this).attr('opacity', 0.7);
    })
    .on('mousemove', function (event) {
      tooltip.style('top', (event.pageY - 10) + 'px')
             .style('left', (event.pageX + 10) + 'px');
    })
    .on('mouseout', function () {
      tooltip.style('visibility', 'hidden');
      d3.select(this).attr('opacity', 1);
    });

    // Add transition to the bars
    chart.selectAll('.bar')
      .attr('width', 0)
      .transition()
      .duration(800)
      .attr('width', d => x(d.percentage));

    // Add the x-axis
    chart.append('g')
      .attr('transform', `translate(0,${height})`)
      .call(d3.axisBottom(x).ticks(numTicks));

    // Add the y-axis
    chart.append('g')
      .call(d3.axisLeft(y));
      

    // Add x-axis label
    //svg.append('text')
     // .attr('class', 'label')
     // .attr('x', width / 2 + margin.left)
     // .attr('y', height + margin.top + 30)
     // .style('text-anchor', 'middle')
     // .text('Percentage of Scam Reports');

  };

  // Function to draw a pie chart
  const drawPieChart = (data, svgRef) => {
    const svg = d3.select(svgRef.current);
    const width = 250; // Width of the SVG container
    const height = 250; // Height of the SVG container
    const radius = Math.min(width-100, height-100) / 2; // Radius of the pie chart
    svg.selectAll("*").remove();
  
    svg.attr('width', width).attr('height', height);
  
    // Define the main group element of the pie chart
    const g = svg.append('g')
      .attr('transform', `translate(${width / 2}, ${height / 2})`);
  
      // Define your custom color palette
    const myColorPalette = [
      '#1d3557', 
      '#457b9d', 
      '#a8dadc', 
      '#f1faee', 
      '#ec9a9a', 
      '#e63946', 
    ];

    // Use the custom color palette for the color scale
    const color = d3.scaleOrdinal(myColorPalette);
  
    // Generate the pie chart data
    const pie = d3.pie()
      .sort(null) // Keep the order of data as in the array
      .value(d => d.value)(data);
  
    // Define the arc generator for the paths of the pie chart slices
    const arc = d3.arc()
      .innerRadius(0)
      .outerRadius(radius);
  
    // Define the arc for the hover state (enlarged)
    const arcHover = d3.arc()
      .innerRadius(0)
      .outerRadius(radius * 1.1); // Increase the radius for the hover state
  
    // Create the pie chart slices
    const slices = g.selectAll('.slice')
      .data(pie)
      .enter().append('path')
      .attr('class', 'slice')
      .attr('d', arc)
      .attr('fill', d => color(d.data.category))
      .style('stroke', 'white')
      .style('stroke-width', '2px');
  
    // Hover interaction
    slices.on('mouseover', function(event, d) {
      d3.select(this).transition()
        .duration(200)
        .attr('d', arcHover);
  
      // Show the tooltip text
      g.append('text')
        .attr('class', 'tooltip')
        .attr('text-anchor', 'middle')
        .attr('dy', '-1.25em') // Position above the center
        .style('font-size', '1em')
        .style('font-weight', 'bold')
        .style('fill', '#ffffff')
        .text(`${d.data.category}: ${d.data.value}%`);
    })
    .on('mouseout', function() {
      d3.select(this).transition()
        .duration(200)
        .attr('d', arc);
  
      // Remove the tooltip text
      g.select('.tooltip').remove();
    });
    // Adjust styles in CSS
  };

  return (
    <div>
      {/* Background section with inline styles */}
      <section style={{
        height: '1755px', /*height of the page*/
        background: '#ffff', /*background colour of the page*/
      }}>
        <Navbar />
        <div className="all-viz-container"
          style={{
            backgroundImage: `url(${scamTrendsimage})`,
            backgroundSize: 'cover', /* Adjust to fill the container */
            backgroundPosition: 'center', /* Center the image in the container */
            backgroundRepeat: 'no-repeat', /* Prevent image tiling */
            width: '100vw',
            height: '50vw',
          }}>
          <div className="cards-container">
            <div class="bank-inst-card">
              <img width="200" height="200" src={Box1} alt="bank-building" />
              <div class="card__content">
                <p class="card__title">Age Group 65+</p>
                <p class="card__description">People over the age of <strong>65</strong> were the only age group to experience an <strong>increase</strong> in reported losses since 2023.</p>
              </div>
            </div>
            <div class="bank-inst-card">
              <img width="200" height="200" src={Box2} alt="bank-building" />
              <div class="card__content">
                <p class="card__title">Increase in Losses</p>
                <p class="card__description">Losses for people over the age of 65 increased by <strong>13.3 %</strong> in 2023 <strong>($120 million)</strong> while other age groups reported a decrease in loss.</p>
              </div>
            </div>
            <div class="bank-inst-card">
              <img width="200" height="200" src={Box3} alt="bank-building" />
              <div class="card__content">
                <p class="card__title">Text Messages</p>
                <p class="card__description">Text messages were the most reported contact method by the victims with 109,621 reports <strong>(37.3% increase from 2022).</strong></p>
              </div>
            </div>
            <div class="bank-inst-card">
              <img width="200" height="200" src={Box4} alt="bank-building" />
              <div class="card__content">
                <p class="card__title">Investment Scams</p>
                <p class="card__description">Australians over the age of 65 were disproportionally impacted by investment scams due to their <strong>retirement savings</strong> looking for investment options.</p>
              </div>
            </div>
          </div>
          <div className='button-trendx-down'>
            <a href="#fin-losses" className="button-trend-down">
              {/* Downward arrow SVG icon */}
              <svg className="trend-svgIcon-down" viewBox="0 0 384 512">
                <path
                  d="M214.6 41.4c-12.5-12.5-32.8-12.5-45.3 0l-160 160c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L160 141.2V448c0 17.7 14.3 32 32 32s32-14.3 32-32V141.2L329.4 246.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3l-160-160z"
                ></path>
              </svg>
            </a>
          </div>
        </div>

        <div className="visualization-section" id='fin-losses'
          style={{
            backgroundImage: `url(${scamImpactsimage})`,
            backgroundSize: 'cover', /* Adjust to fill the container */
            backgroundPosition: 'center', /* Center the image in the container */
            backgroundRepeat: 'no-repeat', /* Prevent image tiling */
            width: '100vw',
            height: '50vw'
          }}>
          <div className="impact-cards-container" >
            <div class="new-viz-card">
              <div class="new-viz-card-inner">
                <div class="new-viz-card-front">
                  <img src={firstImage} alt="Description for first image" className="info-image" />
                </div>
                <div class="new-viz-card-back">
                  <p>If the investment opportunity looks too good to be true, it usually is.</p>
                </div>
              </div>
            </div>
            <div class="new-viz-card">
              <div class="new-viz-card-inner">
                <div class="new-viz-card-front">
                  <img src={secondImage} alt="Description for first image" className="info-image" />
                </div>
                <div class="new-viz-card-back">
                  <p>Scammers pretend to be reputable companies, loved ones, or acquaintances in order to obtain your money or sensitive personal details.</p>
                </div>
              </div>
            </div>
            <div class="new-viz-card">
              <div class="new-viz-card-inner">
                <div class="new-viz-card-front">
                  <img src={thirdImage} alt="Description for first image" className="info-image" />
                </div>
                <div class="new-viz-card-back">
                  <p>Scammers exploit friendships and dating to gain your trust and steal your money.</p>
                </div>
              </div>
            </div>
          </div>
        </div>


        {/* Button to scroll up */}
			<div className='button-homex'>
				<a href="#" className="button-home">
					{/* Upward arrow SVG icon */}
					<svg className="svgIcon" viewBox="0 0 384 512">
						<path
							d="M214.6 41.4c-12.5-12.5-32.8-12.5-45.3 0l-160 160c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L160 141.2V448c0 17.7 14.3 32 32 32s32-14.3 32-32V141.2L329.4 246.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3l-160-160z"
						></path>
					</svg>
				</a>
			</div>


			{/*Bottom nav bar */}
			<div className="radio-input">
				<label>
					<input
						value="value-1"
						name="value-radio"
						id="value-1"
						type="radio"
						checked={selectedValue === 'value-1'}
						onChange={handleChange}
					/>
					<a href="/" target="_top">Home</a>
				</label>
				<label>
					<input
						value="value-2"
						name="value-radio"
						id="value-2"
						type="radio"
						checked={selectedValue === 'value-2'}
						onChange={handleChange}
					/>
					<a href="/detectscam" target="_top">Detect Scam</a>
				</label>
				<label>
					<input
						value="value-3"
						name="value-radio"
						id="value-3"
						type="radio"
						checked={selectedValue === 'value-3'}
						onChange={handleChange}
					/>
					<a href="/helpsupport" target="_top">Help & Support</a>
				</label>
				<label>
					<input
						value="value-4"
						name="value-radio"
						id="value-4"
						type="radio"
						checked={selectedValue === 'value-4'}
						onChange={handleChange}
					/>
					<a href="/scamquiz" target="_top">Scam Spotter</a>
				</label>
				<label>
					<input
						value="value-5"
						name="value-radio"
						id="value-5"
						type="radio"
						checked={selectedValue === 'value-5'}
						onChange={handleChange}
					/>
					<a href="/scamtypes" target="_top">Scam Types</a>
				</label>
				<label>
					<input
						value="value-6"
						name="value-radio"
						id="value-6"
						type="radio"
						checked={selectedValue === 'value-6'}
						onChange={handleChange}
					/>
					<a href="/scamtrends" target="_top">Scam Statistics</a>
				</label>
				<span className="selection"></span>
			</div>

      </section>

      {/*footer*/}
      <footer className="footer">
          <div className="footer-center">
            <p className="text-footer">
              © 2024 <strong>Agile Rangers</strong>. All rights reserved.
            </p>
          </div>
        </footer>
    </div>
  )
}

export default ScamTrends