import React, { useState } from 'react';
import Navbar from '../components/Navbar'; // Import Navbar component
import service from '../axios/service';
import Leaderboard from '../components/Leaderboard';
import { Link, useNavigate } from 'react-router-dom';
import { BsArrowLeft, BsArrowRight } from 'react-icons/bs';


const questions = [
    {
    questionText: 'Question 1',
    answerOptions: [
        { answerText: 'Scam', isCorrect: true },
        { answerText: 'Not a scam', isCorrect: false },
        
    ],
    explanation: 'Explanation',
    },
    {
    questionText: 'Question 2',
    answerOptions: [
        { answerText: 'Scam', isCorrect: true },
        { answerText: 'Not a scam', isCorrect: false },
        
    ],
    explanation: 'Explanation',
    },
    {
    questionText: 'Question 3',
    answerOptions: [
        { answerText: 'Scam', isCorrect: false },
        { answerText: 'Not a scam', isCorrect: true },
        
    ],
    explanation: 'Explanation',
    },
    {
    questionText: 'Question 4',
    answerOptions: [
        { answerText: 'Scam', isCorrect: true },
        { answerText: 'Not a scam', isCorrect: false },
        
    ],
    explanation: 'Explanation',
    },
    {
    questionText: 'Question 5',
    answerOptions: [
        { answerText: 'Scam', isCorrect: false },
        { answerText: 'Not a scam', isCorrect: true },
        
    ],
    explanation: 'Explanation',
    },

];
  

function Quiz() {

  const navigate = useNavigate(); // Navigate function for programmatic navigation
    const [selectedValue, setSelectedValue] = useState('value-4'); // Initial selected value

    const handleChange = (event) => {
      const selectedValue = event.target.value;
      setSelectedValue(selectedValue); // Update the selected value in state
      switch (selectedValue) {
      case 'value-1':
        navigate('/');
        break;
      case 'value-2':
        navigate('/detectscam');
        break;
      case 'value-3':
        navigate('/helpsupport');
        break;
      case 'value-4':
        navigate('/scamquiz');
        break;
      case 'value-5':
        navigate('/scamtypes');
        break;
      case 'value-6':
        navigate('/scamtrends');
        break;
      default:
        break;
      }
    };

    const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
    const [showExplanation, setShowExplanation] = useState(false);
    const [score, setScore] = useState(0);
    const [username, setUsername] = useState('');
    const [percentileRank, setPercentileRank] = useState(null);
    const [topScores, setTopScores] = useState([]);
    const [showResults, setShowResults] = useState(false);
    const [showUsernameInput, setShowUsernameInput] = useState(true);
    const [usernameSubmitted, setUsernameSubmitted] = useState(false);
    const [userRank, setUserRank] = useState(null);

    
    const handleAnswerOptionClick = (isCorrect) => {
        if (isCorrect) {
        setScore(score + 1);
        }
        setShowExplanation(true); // After answer question, show result
    };
    
    const handleNextQuestion = () => {
        if (currentQuestionIndex < questions.length - 1) {
        setCurrentQuestionIndex(currentQuestionIndex + 1);
        setShowExplanation(false); // Move on to the next question and hide the parsing
        } else {
        setShowExplanation(false); // Hide parsing and prepare to display the final result
        }
    };
    
    const handleShowResults = async () => {
        try {
            
            setShowResults(true);
    
            // send post to API
            const response = await service({
                method: 'POST',
                url: '/score-submit',
                data: {
                    username: username,  
                    score: score
                }
            });
    
            setTopScores(response.data.top_scores);
            setPercentileRank(response.data.percentile_rank);
            setUserRank(response.data.user_rank);
        } catch (error) {
            console.error('Error submitting score:', error);  
        }
    };

    const handleUsernameSubmit = () => {
        if (username.trim()) {
          setUsernameSubmitted(true); // 
          setShowUsernameInput(false); // 
        } else {
          alert('Please enter a valid username.'); //
        }
      };
    
      return (
        <div>
        {/* Background section with inline styles */}
        <section style={{
          width: '100%',
          height: '172vh',
          /*background: 'linear-gradient(135deg, #AEECEF, #017b93)',*/
          backgroundColor: '#F3F3F3',
          backgroundImage: `linear-gradient(0deg, transparent 24%, var(--color) 25%, var(--color) 26%, transparent 27%,transparent 74%, var(--color) 75%, var(--color) 76%, transparent 77%,transparent),
      linear-gradient(90deg, transparent 24%, var(--color) 25%, var(--color) 26%, transparent 27%,transparent 74%, var(--color) 75%, var(--color) 76%, transparent 77%,transparent)`,
    '--color': '#E1E1E1',
          backgroundSize: '55px 55px',
          boxShadow: 'inset 0 0 0 2000px rgba(255, 255, 255, 0.45)',
        }}>

        <Navbar />


        {showUsernameInput ? (
            /* Username input card */
            <div className="quiz-card">
              <span className="quiz-card__title">Welcome to Scam Quiz</span>
              <p className="quiz-card__content">Enter a username to start the quiz</p>
                <div className="quiz-card__form">
                  <input
                      type="text"
                      value={username}
                      onChange={(e) => setUsername(e.target.value)}
                      placeholder="Username"
                  />
                <button className='sign-up' onClick={handleUsernameSubmit}>Submit</button>
              </div>
            </div>


      ) : usernameSubmitted ? (
        showResults ? (
          <div className='score'>
            <div className="score-section">
              <p class='question-count'>Score board</p>
              <div class='score-text'>
                  <p class='quiz-text'>Username: {username}</p>
                  <p class='quiz-text'>Points scored: {score}</p>
                  <p class='quiz-text'>Total points: 5</p>
                  <p className='quiz-text'>User rank: {userRank}</p>
                  {/*<p class='quiz-text'>Your percentile rank is: {percentileRank ? `${percentileRank.toFixed(2)}%` : ''}</p>
                  <p class='quiz-text'>Top five scores:</p>
                  <ol>
                    {topScores.map((item, index) => (
                      <li key={index}>{item.username}: {item.score}</li>
                    ))}
                  </ol>*/}
              </div>
            </div>
            <div className='board'>
            {/*leaderboard*/}
            <div className='card-heading'>
              <p className="centered-description"><strong>Leaderboard</strong></p>
            </div>
              <Leaderboard/ >
{/* Button to scroll up */}
<div className='button-homex'>
          <a href="#" className="button-home">
            {/* Upward arrow SVG icon */}
            <svg className="svgIcon" viewBox="0 0 384 512">
              <path
                d="M214.6 41.4c-12.5-12.5-32.8-12.5-45.3 0l-160 160c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L160 141.2V448c0 17.7 14.3 32 32 32s32-14.3 32-32V141.2L329.4 246.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3l-160-160z"
              ></path>
            </svg>
          </a>
        </div>


        {/*Bottom nav bar */}
        <div className="radio-input">
          <label>
            <input
              value="value-1"
              name="value-radio"
              id="value-1"
              type="radio"
              checked={selectedValue === 'value-1'}
              onChange={handleChange}
            />
            <a href="/" target="_top">Home</a>
          </label>
          <label>
            <input
              value="value-2"
              name="value-radio"
              id="value-2"
              type="radio"
              checked={selectedValue === 'value-2'}
              onChange={handleChange}
            />
            <a href="/detectscam" target="_top">Detect Scam</a>
          </label>
          <label>
            <input
              value="value-3"
              name="value-radio"
              id="value-3"
              type="radio"
              checked={selectedValue === 'value-3'}
              onChange={handleChange}
            />
            <a href="/helpsupport" target="_top">Help & Support</a>
          </label>
          <label>
            <input
              value="value-4"
              name="value-radio"
              id="value-4"
              type="radio"
              checked={selectedValue === 'value-4'}
              onChange={handleChange}
            />
            <a href="/scamquiz" target="_top">Scam Spotter</a>
          </label>
          <label>
            <input
              value="value-5"
              name="value-radio"
              id="value-5"
              type="radio"
              checked={selectedValue === 'value-5'}
              onChange={handleChange}
            />
            <a href="/scamtypes" target="_top">Scam Types</a>
          </label>
          <label>
            <input
              value="value-6"
              name="value-radio"
              id="value-6"
              type="radio"
              checked={selectedValue === 'value-6'}
              onChange={handleChange}
            />
            <a href="/scamtrends" target="_top">Scam Statistics</a>
          </label>
          <span className="selection"></span>
        </div>
            </div>
         </div>
        ) : (

          <div>
          {/* Quiz question section */}
          <div className='quiz-section'> 
            {/* Quiz question card */}
            <div className="question-section">
              <div className="question-count">
                <span>Question {currentQuestionIndex + 1}</span>/{questions.length}
              </div>
              {/* Quiz question 1 */}
              {currentQuestionIndex === 0 && ( // Check if current question is Question 1
                <div className="question-text">
                  <p class='quiz-text'>You have received the message below from an unknown sender.</p>
                  <p class='quiz-text'>Identify whether it is a scam message or not.</p>
                  <img src={require('../assets/Easlink.png')} alt="Message Image" />
                </div>
              )}
              {/* Quiz question 2 */}
              {currentQuestionIndex === 1 && ( // Check if current question is Question 2
                <div className="question-text">
                  <p class='quiz-text'>You have received the message below from an unknown sender.</p>
                  <p class='quiz-text'>Identify whether it is a scam message or not.</p>
                  <img src={require('../assets/Shipping issue.png')} alt="Message Image" />
                </div>
              )}
              {/* Quiz question 3 */}
              {currentQuestionIndex === 2 && ( // Check if current question is Question 1
                <div className="question-text">
                  <p class='quiz-text'>You receive this message knowing that Tommy Hilfiger is a reputable clothing retailer.</p>
                  <p class='quiz-text'>Identify whether it is a scam message or not.</p>
                  <img src={require('../assets/tommy.png')} alt="Message Image" />
                </div>
              )}
              {/* Quiz question 4 */}
              {/*{currentQuestionIndex === 3 && ( // Check if current question is Question 1
                <div className="question-text">
                  <p class='quiz-text'>You have received the message below from an unknown sender.</p>
                  <p class='quiz-text'>Identify whether it is a scam message or not.</p>
                  <img src={require('../assets/Eastlink payment.png')} alt="Message Image" />
                </div>
              )}*/}
              {/* Quiz question 5 */}
              {currentQuestionIndex === 3 && ( // Check if current question is Question 3
                <div className="question-text">
                  <p class='quiz-text'>You have received the message below from an unknown sender.</p>
                  <p class='quiz-text'>Identify whether it is a scam message or not.</p>
                  <img src={require('../assets/Overdue notice.png')} alt="Message Image" />
                </div>
              )}
              {/* Quiz question 6 */}
              {currentQuestionIndex === 4 && ( // Check if current question is Question 1
                <div className="question-text">
                  <p class='quiz-text'>You receive the below message whilst paying your bills via Internet Banking.</p>
                  <p class='quiz-text'>Identify whether it is a scam message or not.</p>
                  <img src={require('../assets/bankscam.png')} alt="Message Image" />
                </div>
              )}

              


              {/* Quiz answer section */}
              <div className="answer-section">
                {questions[currentQuestionIndex].answerOptions.map((option, index) => (
                  <button
                    key={index}
                    className={option.isCorrect ? 'is-not-scam' : 'is-scam'}
                    onClick={() => handleAnswerOptionClick(option.isCorrect)}
                    disabled={showExplanation}
                  >
                    {option.answerText}
                  </button>
                ))}
              </div>
            </div>
            </div>
            
            {/* Quiz explanation section */}
            <div className="explanation">   
            {showExplanation && (
              <div className="explanation-section">
                <p>{questions[currentQuestionIndex].explanation}</p>
                {/* Quiz answer 1 */}
                {currentQuestionIndex === 0 && ( // Check if current question is Question 1
                  <div className='explanation-text'>
                    <p class='quiz-text'>It is a scam message.</p>
                    <p class='quiz-text'>Suspicious URL was definitely a red flag. Be sure to check directly with your toll provider.</p>
                  </div>
                )}
                {/* Quiz answer 2 */}
                {currentQuestionIndex === 1 && ( // Check if current question is Question 2
                  <div className='explanation-text'>
                    <p class='quiz-text'>It is a scam message.</p>
                    <p class='quiz-text'>If you have ordered something but do not remember the details, always check your tracking number on the delivery company’s communication channel. Go to the source.</p>
                  </div>
                )}
                {/* Quiz answer 3 */}
                {currentQuestionIndex === 2 && ( // Check if current question is Question 6
                  <div className='explanation-text'> 
                    <p class='quiz-text'>It is not a scam message.</p>
                    <p class='quiz-text'>If you are unsure of any messages, always check the official website. Reputable companies will always have their websites updated on their offers.  </p>
                  </div>
                )}
                {/* Quiz answer 4 */}
                {/*{currentQuestionIndex === 3 && ( // Check if current question is Question 4
                  <div className='explanation-text'> 
                    <p class='quiz-text'>It is a scam message.</p>
                    <p class='quiz-text'>If the link looks suspicious, it usually is. Linkt will never send a text to you. If you are unsure, it is always good to check the Eastlink website.  </p>
                  </div>
                )}*/}
                {/* Quiz answer 5 */}
                {currentQuestionIndex === 3 && ( // Check if current question is Question 3
                  <div className='explanation-text'> 
                    <p class='quiz-text'>It is a scam message.</p>
                    <p class='quiz-text'>Do not click the links, call the number, or reply to the sender. If you are unsure of your toll bill from Linkt, you can navigate to the Linkt website or download the Linkt app. </p>
                  </div>
                )}
                {/* Quiz answer 6 */}
                {currentQuestionIndex === 4 && ( // Check if current question is Question 5
                  <div className='explanation-text'> 
                    <p class='quiz-text'>It is not a scam message.</p>
                    <p class='quiz-text'>Never share your security code with anyone. Your bank will never ask you for the security code. </p>
                  </div>
                )}
                
                


                {/*Button to next question or show result*/}
                <button onClick={currentQuestionIndex < questions.length - 1 ? handleNextQuestion : handleShowResults}>
                  {currentQuestionIndex < questions.length - 1 ? 'Next question' : 'Show result'}
                </button>
                {/*Button to exit quiz*/}
                <div className='quiz-end'>
                  <Link to='/scamquiz' className='button-4'>Exit quiz</Link>
                </div>
              </div>
            )}
          </div> 
          </div>
        )
      ) : null}

      
          </section>

          <footer className="footer">
            <div className="footer-center">
              <p className="text-footer">
                © 2024 <strong>Agile Rangers</strong>. All rights reserved.
              </p>
            </div>
          </footer>

        </div>
      );
      
}
    
export default Quiz;