import React, { useState, useRef} from 'react'; // Import React and useState hook for managing state
import { Link, useNavigate } from 'react-router-dom'; // Import Link and useNavigate from react-router-dom for navigation
import '../App.css'; // Import CSS file for global styles
import Navbar from '../components/Navbar'; // Import Navbar component
import Image from '../assets/blueplain.png'; // Import image asset
import ScamAlertTips from '../components/Scamalerts';

import axios from 'axios'; // Import axios for making HTTP requests
import { BsArrowLeft, BsArrowRight } from 'react-icons/bs'; // Import the arrow left icon from React Icons
import service from '../axios/service';

// Define the Detectscam component
const Detectscam = () => {
  const resultsRef = useRef(null); 
  const [message, setMessage] = useState(''); // State for message input
  const [results, setResults] = useState(''); // State for prediction results
  const navigate = useNavigate(); // Navigate function for programmatic navigation
  const clearBoth = () => {
    setMessage('');
    setResults('');

  };


	const [selectedValue, setSelectedValue] = useState('value-2'); // Initial selected value

	const handleChange = (event) => {
		const selectedValue = event.target.value;
		setSelectedValue(selectedValue); // Update the selected value in state
		switch (selectedValue) {
		case 'value-1':
			navigate('/');
			break;
		case 'value-2':
			navigate('/detectscam');
			break;
		case 'value-3':
			navigate('/helpsupport');
			break;
		case 'value-4':
			navigate('/scamquiz');
			break;
		case 'value-5':
			navigate('/scamtypes');
			break;
		case 'value-6':
			navigate('/scamtrends');
			break;
		default:
			break;
		}
	};

  // Function to handle search button click
  const handleSearch = async () => {
    if (!message.trim()) { // Checks if the message is empty or only whitespace
      setResults(""); // Clear results to show default message in the results section
      resultsRef.current?.scrollIntoView({ behavior: 'smooth', block: 'start' }); // Scroll to results section
      return; // Exit the function if no message is entered

  }
    try {
      // Update to use the Flask proxy endpoint
      const endpoint = '/predict'; 
      const payload = { message: message }; // Ensure the payload matches backend expectations
      // Make a POST request to the Flask proxy endpoint
      const response = await service.post(endpoint, payload);
      const responsedata = JSON.parse(response.data);
      // Update results with the prediction. Ensure response format matches  
      setResults(responsedata.result);
      resultsRef.current?.scrollIntoView({ behavior: 'smooth', block: 'start' })
    } catch (error) {
      setResults('Error making prediction');
      resultsRef.current?.scrollIntoView({ behavior: 'smooth', block: 'start' })
    }
  };

  

  return (
    <div>
      {/* Background section with inline styles */}
      <section style={{
        /*backgroundImage: `url(${Image})`, // Set background image
        backgroundSize: 'cover', // Cover the entire background
        backgroundRepeat: 'no-repeat', // Do not repeat the background
        backgroundAttachment: 'fixed', // Fix the background position
        backgroundBlendMode: 'darken', // Blend mode for the background
        boxShadow: 'inset 0 0 0 2000px rgba(255, 255, 255, 0.15)', // Create a background overlay */
        width: '100%',
        height: '100%',
        background: 'linear-gradient(135deg, #AEECEF, #017b93)',
      }}>
        {/* Render the Navbar component */}
        <Navbar />

        {/* Search tool section */}
        <section className="search-tool">
          <div className="box-content">
            <h1 className="centered-textdetect">
            How likely is your message a scam? Enter to find out !
            </h1>
            <p className="centered-info">
              Just paste the suspicious text message into the box below and press 'Check' to see if it's safe!
            </p>
            {/* Disclaimer */}
            <p className="disclaimer">
              Disclaimer : We've designed our scam detection tool to help you identify potential SMS scams, but please remember that the accuracy of the results can vary. Our tool’s effectiveness depends on the data it has been trained on, and it may not always capture every scam accurately.
              The current accuracy of the tool is 94%.
            </p>
            <ScamAlertTips />

            {/* Card with user guide instructions */}
            <div className='flex-container'>
              <div className="card-containerd">
                <div className="info-cardx">
                  <div className="card-contentx">
                    <h3>User Guide:</h3>
                    <ol>
                      {/* List of user guide steps */}
                      <li>Open the message in your phone</li>
                      <li>Press and hold onto the message</li>
                      <li>Look for the "copy" symbol and select it</li>
                      <li>On this page, press and hold in the text box</li>
                      <li>Select "paste" symbol</li>
                      <li>Click Check</li>
                      <li>Scroll down to see the result</li>
                    </ol>
                  </div>
                </div>
              </div>


              {/* Search box for entering message */}
              

              <div className="input-area">
                <div className="search-box">
                  <textarea id="messageInput" placeholder="Enter text message here." value={message} onChange={(e) => setMessage(e.target.value)} ></textarea>
                </div>
                <div class="checkclear-container">
                  {/* Search and clear buttons */}
                  <button className='check-button' onClick={handleSearch}><span>Check</span></button>
                  <button className='check-button' onClick={clearBoth}> <span>Clear</span></button>
                </div>
              </div>
            </div>
          </div>
        
          </section>
    

        {/* Section to display search results */}
        <section ref={resultsRef} className="result-section1">
           <h1 className="centered-textd">
           {results
                    ? `The probability of this message being a scam is ${results}`
                    : "Enter a message to check if it is scam or not !"}
          </h1>
          </section>
          


        {/* Section for result actions */}
        {/*<section className="result-section">
          <div className="result-actions">
            <button className="homeButton" onClick={() => navigate('/')}><BsArrowLeft/>Home</button>
            <button className="learn-more" onClick={() => navigate('/helpsupport')}>Help & Support <BsArrowRight /></button>
          </div>
        </section>*/}

        {/* Button to scroll up */}
			<div className='button-homex'>
				<a href="#" className="button-home">
					{/* Upward arrow SVG icon */}
					<svg className="svgIcon" viewBox="0 0 384 512">
						<path
							d="M214.6 41.4c-12.5-12.5-32.8-12.5-45.3 0l-160 160c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L160 141.2V448c0 17.7 14.3 32 32 32s32-14.3 32-32V141.2L329.4 246.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3l-160-160z"
						></path>
					</svg>
				</a>
			</div>


			{/*Bottom nav bar */}
			<div className="radio-input">
				<label>
					<input
						value="value-1"
						name="value-radio"
						id="value-1"
						type="radio"
						checked={selectedValue === 'value-1'}
						onChange={handleChange}
					/>
					<a href="/" target="_top">Home</a>
				</label>
				<label>
					<input
						value="value-2"
						name="value-radio"
						id="value-2"
						type="radio"
						checked={selectedValue === 'value-2'}
						onChange={handleChange}
					/>
					<a href="/detectscam" target="_top">Detect Scam</a>
				</label>
				<label>
					<input
						value="value-3"
						name="value-radio"
						id="value-3"
						type="radio"
						checked={selectedValue === 'value-3'}
						onChange={handleChange}
					/>
					<a href="/helpsupport" target="_top">Help & Support</a>
				</label>
				<label>
					<input
						value="value-4"
						name="value-radio"
						id="value-4"
						type="radio"
						checked={selectedValue === 'value-4'}
						onChange={handleChange}
					/>
					<a href="/scamquiz" target="_top">Scam Spotter</a>
				</label>
				<label>
					<input
						value="value-5"
						name="value-radio"
						id="value-5"
						type="radio"
						checked={selectedValue === 'value-5'}
						onChange={handleChange}
					/>
					<a href="/scamtypes" target="_top">Scam Types</a>
				</label>
				<label>
					<input
						value="value-6"
						name="value-radio"
						id="value-6"
						type="radio"
						checked={selectedValue === 'value-6'}
						onChange={handleChange}
					/>
					<a href="/scamtrends" target="_top">Scam Statistics</a>
				</label>
				<span className="selection"></span>
			</div>

        

        {/* Footer section */}
        <footer className="footer">
          <div className="footer-center">
            <p className="text-footer">
              © 2024 <strong>Agile Rangers</strong>. All rights reserved.
            </p>
          </div>
        </footer>
      </section>
        
    </div>
  );
};

export default Detectscam; // Export the Detectscam component as the default export
