import React, { useState } from 'react'; // Import React library
import '../App.css'; // Import CSS file for global styles
import { Link } from 'react-router-dom'; // Import Link component from react-router-dom for navigation
import Navbar from '../components/Navbar'; // Import Navbar component
import Image from '../assets/background.png'; // Import image asset
import BlueImage from '../assets/blueplain.png'; // Import image asset

// Define the Home component
const Home = () => {

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const paragraphStyle = {
    marginBottom: '10px', // Adjust the spacing as needed
  };



  return (
    <div>
      {/* Purple Background section */}
      <section style={{
        width: '100%',
        height: '95vh',
        /*backgroundImage: `linear-gradient(135deg, #AEECEF, #017b93), url(${Image})`, // Set background image
        backgroundSize: 'cover', // Cover the entire background
        backgroundRepeat: 'no-repeat', // Do not repeat the background
        backgroundAttachment: 'fixed', // Fix the background position
        boxShadow: 'inset 0 0 0 2000px rgba(255, 255, 255, 0.45)', // Create a background overlay
        backgroundBlendMode: 'darken', // Blend mode for the background*/

        backgroundImage: `url(${BlueImage})`, // Set background image
        backgroundSize: 'cover', // Cover the entire background
        backgroundRepeat: 'no-repeat', // Do not repeat the background
        backgroundAttachment: 'fixed', // Fix the background position
        backgroundBlendMode: 'darken', // Blend mode for the background
        boxShadow: 'inset 0 0 0 2000px rgba(255, 255, 255, 0.15)', // Create a background overlay 
      }}>
        {/* Render the Navbar component */}
        <Navbar />

        {/* Content section */}
        <section className="content">
          <div className="box-content">
              {/* Title and description */}
              <h1 className="centered-title">
                Welcome to Scam Secure
              </h1>
              <p className="centered-description">
                A one-stop solution that protects and educates the elderly to spot and handle scam messages.
              </p>
              {/* Button to scroll down */}
              <a className='button-17' href="#audience">Explore</a>
              {/* Button to scroll down */}
              {/*<div className='button-homex-down'>
                  <a href="#audience" className="button-home-down">
                    <svg className="svgIcon-down" viewBox="0 0 384 512">
                      <path
                        d="M214.6 41.4c-12.5-12.5-32.8-12.5-45.3 0l-160 160c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L160 141.2V448c0 17.7 14.3 32 32 32s32-14.3 32-32V141.2L329.4 246.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3l-160-160z"
                      ></path>
                    </svg>
                  </a>
              </div>*/}
          </div>
        </section>

      </section>

      {/* White Background section */}
      <section style={{
        width: '100%',
        height: '100vh',
      }}>
        
      <div><h1 className="text-big" id="audience"></h1></div>

      <div className="content-section">
          {/* Sample text */}
          <div className="text-content">
            <p className='question-count' >
              Are you aged 65 or older living in Australia?
              <div className="empty-line"></div> {/* Empty line */}
              This website is for you.
             </p>
            <div className="empty-line"></div> {/* Empty line */}
            <p className='home-text'>
              We address the concerning issue of elder financial abuse, a form of fraud and scams affecting this age group. 
              <div className="empty-line"></div> {/* Empty line */}
              Our goal is to empower individuals with knowledge to recognize and combat online scams effectively.
            </p>
            <div className="empty-line"></div> {/* Empty line */}
            <p className='home-text'>
              
              Explore our website to learn more!
            </p>
          </div>
          
          {/* Image */}
          <div className="image-container">
            {/* Image with dynamic source */}
            <img src={require('../assets/ladywomen.png')} alt="Sample Image" />
          </div>
      </div>

      {/* Button to scroll down */}
      <div className='button-homex-down'>
          <a href="#objectives" className="button-home-down">
            {/* Downward arrow SVG icon */}
            <svg className="svgIcon-down" viewBox="0 0 384 512">
              <path
                d="M214.6 41.4c-12.5-12.5-32.8-12.5-45.3 0l-160 160c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L160 141.2V448c0 17.7 14.3 32 32 32s32-14.3 32-32V141.2L329.4 246.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3l-160-160z"
              ></path>
            </svg>
          </a>
      </div>

      </section>

      {/* Card section */}
      <section style={{
        width: '100%',
        height: '100%',
        background: 'linear-gradient(135deg, #AEECEF, #017b93)',
      }}>

        <div><h1 className="text-big" id="objectives"></h1></div>

        <div className='card-heading'>
          <p className="centered-description"><strong>Our Objectives</strong></p>
        </div>

        {/* Card container for information */}
        <div className="card-container-home">

          {/* Information card 1 */}
          <div className="card-home">
            <div className="image-home">
              {/* Image with dynamic source */}
              <img src={require('../assets/1.png')} alt="Message Image" />
            </div>
            <div className="content-home">
              <div className="content-homex">
                <p className="title-home">Detect Scam</p>
                <p className="desc-home">
                  Use our scam detect tool to find out whether a message is a scam or not. {/*Easily spot a scam message to protect yourself from being a victim. Try it now!*/}
                </p>
              </div>
              <div>
                {/* Link to the Detect Scam page */}
                <Link to="/detectscam" className="action-home" target="_top">Learn more <span aria-hidden="true"> → </span></Link>
              </div>
            </div>
          </div>
          {/* Information card 2 */}
          <div className="card-home">
            <div className="image-home">
              {/* Image with dynamic source */}
              <img src={require('../assets/2.png')} alt="Message Image" />
            </div>
            <div className="content-home">
              <div className="content-homex">
                <p className="title-home">Help & Support</p>
                <p className="desc-home">
                  Exposed to a scam? Act straight away, find out where to find help and support.
                </p>
              </div>
              {/* Link to the Help & Support page */}
              <Link to="/helpsupport" className="action-home" target="_top">Learn more <span aria-hidden="true"> → </span></Link>
            </div>
          </div>
          {/* Information card 3 */}
          <div className="card-home">
            <div className="image-home">
              {/* Image with dynamic source */}
              <img src={require('../assets/3.png')} alt="Message Image" />
            </div>
            <div className="content-home">
              <div className="content-homex">
                <p className="title-home">Scam Spotter</p>
                <p className="desc-home">
                  Take our scam quiz to test your knowledge about scam messages.
                </p>
              </div>
              {/* Link to the Scam Quiz page */}
              <Link to="/scamquiz" className="action-home" target="_top">Learn more <span aria-hidden="true"> → </span></Link>
            </div>
          </div>
          {/* Information card 4 */}
          <div className="card-home">
            <div className="image-home">
              {/* Image with dynamic source */}
              <img src={require('../assets/Typesofscam.png')} alt="Message Image" />
            </div>
            <div className="content-home">
              <div className="content-homex">
                <p className="title-home">Scam Type</p>
                <p className="desc-home">
                  Discover red flags to help you spot different types of scams.
                </p>
              </div>
              {/* Link to the Scam Types page */}
              <Link to="/scamtypes" className="action-home" target="_top">Learn more <span aria-hidden="true"> → </span></Link>
            </div>
          </div>
          {/* Information card 5 */}
          <div className="card-home">
            <div className="image-home">
              {/* Image with dynamic source */}
              <img src={require('../assets/4.png')} alt="Message Image" />
            </div>
            <div className="content-home">
              <div className="content-homex">
                <p className="title-home">Scam Statistics</p>
                <p className="desc-home">
                  Learn about the emerging scam trends and financial impacts of scam messages.
                </p>
              </div>
              {/* Link to the Scam Trends page */}
              <Link to="/scamtrends" className="action-home" target="_top">Learn more <span aria-hidden="true"> → </span></Link>
            </div>
          </div>

        </div>
        
        {/* Button to scroll up */}
        <div className='button-homex'>
          <a href="#" className="button-home">
            {/* Upward arrow SVG icon */}
            <svg className="svgIcon" viewBox="0 0 384 512">
              <path
                d="M214.6 41.4c-12.5-12.5-32.8-12.5-45.3 0l-160 160c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L160 141.2V448c0 17.7 14.3 32 32 32s32-14.3 32-32V141.2L329.4 246.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3l-160-160z"
              ></path>
            </svg>
          </a>
        </div>
      

      {/* Footer section */}
      <footer className="footer">
        <div className="footer-center">
          <p className="text-footer">
            © 2024 <strong>Agile Rangers</strong>. All rights reserved.
          </p>
        </div>
      </footer>

      </section>

    </div>
  );
}

export default Home; // Export the Home component as the default export
