import React from "react";
import "./InvestmentScams.css";
import Navbar from '../components/Navbar';
import { Link, useNavigate } from 'react-router-dom';
import { BsArrowLeft, BsArrowRight } from 'react-icons/bs';

const InvestmentScams = () => {
  const navigate = useNavigate(); 
  return (
    <div>
      <Navbar/>
    <div className="scams-container">
      <div className="scam-intro">
        <h1 className="type-title">Investment Scams</h1>
        <p className="type-subtitle">
        If the investment opportunity looks too good to be true, it usually is.
        </p>
        <div className="type-info-box">
          <p>Scammers make their investment seem too good to be true by using innovative technologies and persuasive marketing. </p>
          <p>The offer is usually big payouts with little to no risk. In addition, pressure tactics are used to get you to act fast.</p>
        </div>
     </div>
      <h2 className="red-flags-title">Red flags that it might be a scam</h2>
      <div className="red-flags-box">
        <ul>
          <li>
            <strong>Fake news stories</strong> or ads that claim a celebrity recommends this scheme to make big money.
          </li>
          <li>
            An online friend that you’ve <strong>never met in person</strong> before start pitching to you about investments.
          </li>
          <li>
            Advertisements, emails, or websites with endorsements and <strong>extravagant claims of large profits</strong>.
          </li>
        </ul>
      </div>
      <h2 className="tips-title">What you can do to protect yourself</h2>
      <div className="tips">
      <ul>
          <li>
          Stop, think, and check before you act
          </li>
          <li>
          Get financial and legal advice from a trusted advisor
          </li>
          <li>
          Check if the company is not named on the <a className="IOSCO-link"href="https://www.iosco.org/investor_protection/Alerts/index.html">International Organization of Securities Commissions (IOSCO) investor alerts</a>
          </li>
        </ul>

      </div>
    </div>
    <section className="result-section">
          <div className="result-actions">
            {/* Navigation buttons */}
            <button className="homeButton" onClick={() => navigate('/scamtypes')}><BsArrowLeft/>Scam Types</button>
            <button className="learn-more" onClick={() => navigate('/romance-scams')}>Romance Scams <BsArrowRight /></button>
          </div>
        </section>
    <footer className="footer">
          <div className="footer-center">
            <p className="text-footer">
              © 2024 <strong>Agile Rangers</strong>. All rights reserved.
            </p>
          </div>
        </footer>
    </div>
  );
};

export default InvestmentScams;