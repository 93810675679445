import React from 'react'; // Import React library
import { Routes, Route } from 'react-router-dom'; // Import Routes and Route components from react-router-dom
import Home from './routes/Home'; // Import Home component from the 'routes' directory
import HelpSupport from './routes/HelpSupport'; // Import HelpSupport component from the 'routes' directory
import DetectScam from './routes/Detectscam'; // Import DetectScam component from the 'routes' directory
import ScamQuiz from './routes/ScamQuiz'; // Import ScamQuiz component from the 'routes' directory
import Quiz from './routes/Quiz';// Import Quiz component from the 'routes' directory
import ScamTypes from './routes/ScamTypes';
import InvestmentScams from './routes/InvestmentScams';
import ImpersonationScams from'./routes/ImpersonationScams';
import RomanceScams from'./routes/RomanceScams';
import ThreatsScams from './routes/ThreatsScams';
import UnexpectedScams from './routes/UnexpectedScams';
import ScamTrends from './routes/ScamTrends';


// Define the App component
function App() {
  return (
    <div>
      {/* Render the Routes component */}
      <Routes>
        {/* Define routes for different paths */}
        <Route path='/' element={<Home />} /> {/* Render the Home component when the path is '/' */}
        <Route path='/helpsupport' element={<HelpSupport />} /> {/* Render the HelpSupport component when the path is '/helpsupport' */}
        <Route path='/detectscam' element={<DetectScam />} /> {/* Render the DetectScam component when the path is '/detectscam' */}
        <Route path='/scamquiz' element={<ScamQuiz />} /> {/* Render the ScamQuiz component when the path is '/scamquiz' */}
        <Route path='/quiz' element={<Quiz />} /> {/* Render the Quiz component when the path is '/quiz' */}
        <Route path='/scamtypes' element={<ScamTypes />} /> {/* Render the ScamTypes component when the path is '/scamtypes' */}
        <Route path='/investment-scams' element={<InvestmentScams />} /> {/* Render the ScamTypes component when the path is '/scamtypes' */}
        <Route path='/impersonation-scams' element={<ImpersonationScams />} /> {/* Render the ScamTypes component when the path is '/scamtypes' */}
        <Route path='/romance-scams' element={<RomanceScams />} /> {/* Render the ScamTypes component when the path is '/scamtypes' */}
        <Route path='/threats' element={<ThreatsScams />} /> {/* Render the ScamTypes component when the path is '/scamtypes' */}
        <Route path='/unexpected' element={<UnexpectedScams />} /> {/* Render the ScamTypes component when the path is '/scamtypes' */}
        <Route path='/scamtrends' element={<ScamTrends />} /> 
        
      </Routes>
    </div>
  );
}

export default App; // Export the App component as the default export



